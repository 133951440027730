<template>
  <div>
    <el-card shadow="never" style="min-height: 800px;height: auto">
      <el-row style="margin-bottom: 22px">
        <el-input v-model="queryInfo.materialCode" class="query-input" placeholder="备件编码" clearable></el-input>
        <el-input v-model="queryInfo.packMaterialCode" class="query-input" placeholder="包材条码" clearable style="margin-left: 10px"></el-input>
        <el-button @click="currentChange(1)" type="primary" :loading="loading" class="query-btn">查询</el-button>
        <el-button type="primary" @click="dialogVisible = true">添加</el-button>
        <el-button type="primary" @click="impDialogVisible = true">批量导入</el-button>
      </el-row>

      <el-table :data="dataList" border style="width: 100%" stripe>
        <el-table-column align="center" prop="materialCode" label="备件编码"></el-table-column>
        <el-table-column align="center" prop="packMaterialCode" label="包材条码"></el-table-column>
        <el-table-column align="center" prop="updateTime" label="修改时间"></el-table-column>
        <el-table-column align="center" prop="updateUserName" label="修改人"></el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button @click="deleteData(scope.row)" type="danger" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="page-box">
        <el-pagination background layout="total,prev, pager, next" :page-size="pageSize" :total="total" @current-change="currentChange" :current-page.sync="queryInfo.pageNo"></el-pagination>
      </div>
    </el-card>
    <el-dialog title="添加备件包材" :visible.sync="dialogVisible" :close-on-click-modal="false" width="600px" :before-close="handleClose">
      <el-form v-model="formData" label-width="200px">
        <el-form-item label="备件编码：">
          <el-input v-model.trim="formData.materialCode" placeholder="请输入备件编码" class="query-input" clearable></el-input>
        </el-form-item>
        <el-form-item label="包材条码：">
          <el-input v-model.trim="formData.packMaterialCode" placeholder="请输入包材条码" class="query-input" clearable></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="addPackSubClass">确定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="导入备件包材" :visible.sync="impDialogVisible" :close-on-click-modal="false" width="800px" :before-close="impHandleClose">
      <ImportPackMaterial/>
      <div slot="footer" class="dialog-footer">
        <el-button @click="impHandleClose">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ImportPackMaterial from "@/views/material/ImportPackMaterial";

export default {
  name: "PackMaterialRelation",
  components: {ImportPackMaterial},
  data() {
    return {
      queryInfo: {
        packMaterialCode: '',
        materialCode: '',
        pageNo: 1
      },
      formData: {
        packMaterialCode: '',
        materialCode: '',
      },
      dataList: [],
      total: 0,
      pageSize: 0,
      dialogVisible: false,
      impDialogVisible: false,
      loading: false
    }
  },
  created() {
    this.queryList();
  },
  methods: {
    queryList() {
      this.loading = true
      this.$axios.post('packMaterialRelation/queryMaterial', this.queryInfo).then(response => {
        this.loading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }

        this.dataList = res.data.records;
        this.total = res.data.total;
        this.pageSize = res.data.size;
        this.loading = false;

      }, error => {
        this.loading = false;
        return this.$message.error('查询失败，' + error.message);
      });
    },
    currentChange(pageNo) {
      this.queryInfo.pageNo = pageNo;
      this.queryList()
    },
    handleClose() {
      this.dialogVisible = false;
    },
    impHandleClose() {
      this.queryList()
      this.impDialogVisible = false;
    },
    async addPackSubClass() {
      if (!this.formData.packMaterialCode) {
        return this.$message.error('包材条码不能为空');
      }
      if (!this.formData.materialCode) {
        return this.$message.error('备件编码不能为空');
      }

      const {data: res} = await this.$axios.post('packMaterialRelation/add', this.formData);
      if (res.code !== 0) {
        return this.$message.error(res.message);
      }
      this.$message.success("添加成功");
      this.queryList();
      this.handleClose()
      this.formData.packMaterialCode = ''
      this.formData.materialCode = ''
    },
    deleteData(row) {
      this.$confirm('确定删除吗？', '提示', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning',
      }).then(() => {
        this.$axios.post('packMaterialRelation/delete', row.materialCode).then(response => {
          if (response.data.code !== 0) {
            return this.$message.error(response.data.message);
          }
          this.$message.success("删除成功");
          this.queryList();
        })
      })
    },
  }
}
</script>

<style scoped>
.query-input {
  width: 250px;
}

.query-btn {
  margin-left: 10px;
}
</style>